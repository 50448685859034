import React from 'react';
import { Link } from "gatsby";
import WoodHome from '../../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import Advantages from '../../components/advantages/advantages';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import PriceList from '../../components/price-list/price-list';


const PokraskaDomaIzSruba = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Конопатка сруба', url:'#', status:true}]
  return (
    <div>
        <SEO title="Конопатка сруба в Калининградской области"
            description="Опытные бригады в срок выполнят работы по конопатке сруба в Калининграде.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Конопатка сруба в Калининград&shy;ской области'} 
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify">
                <h1 className="text-md-left">Конопатка сруба</h1>
                <p>
                Конопаткой называется процесс заделки стыков и щелей между бревнами в бревенчатом доме или строении из сруба.
                 Основное назначение этого процесса – повышение теплоэффективности строения. <br />
                 Для конопатки деревянного дома используются природные утеплители – мох, пакля, джут, льноватин, которые забиваются
                  в межвенцовые щели при помощи специального инструмента – деревянной или металлической лопатки.
                   Выбор пал именно на эти материалы неслучайно: помимо того, что они являются экологически чистыми,
                    они обладают другими важными характеристиками.</p>
                <Advantages />
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={WoodHome} className="w-100" style={{borderRadius:"0px 0px 150px 14px"}}  alt="" title="" />
              </Col>
            </Row>
        </Container>
        <Container fluid className="">
          <Container className="mt-2 p-1 p-md-3">
            <Row>
              <h2>Цены на работы по наружной окаске сруба</h2>
            </Row>
          </Container>
         </Container>
         <Container className="mb-5">
           <Row>
             <Col md="12">
             <h3>Преимущества натуральных утеплителей:</h3>
                  <ul>
                    <li>- устойчивы к колебаниям температуры и влажности</li>
                    <li>- имеют низкую теплопроводность</li>
                    <li>- отличаются гигроскопичностью</li>
                  </ul>
             </Col>
             <Col md="12">
             <h3>Недостатки конопатки натуральными утеплителями:</h3>
              <p>Привычная многим хозяевам конопатка добавляет проблем каждый сезон. 
                Весной паклю растаскивают птицы. Летом в швах заводятся насекомые — моль, мокрицы, осы. 
                Осенью стены заливают косые дожди, остатки пакли отсыревают: грибок и плесень портит внутреннюю отделку. 
                По зиме вода превращается в лед, и расширяясь в объеме, разрушает стену. 
                Каждую весну — сезон конопатки, и заново по кругу.           
                </p>
                <span class="separator-fullScreen mt-5 mb-5 d-none d-md-block"></span>
                <h2>Мы рекомендуем утепление деревянного дома по технологии "Теплый шов"</h2>
                <p>Герметизация межвенцовых стыков по новой технологии "Тёплый шов" производится при помощи акриловых или силиконовых герметиков.
                  </p>
                  
                  <h3>Герметизация по технологии "Теплый шов" обладает следующими достоинствами:</h3>
                  <ul>
                        <li>- эластичен</li>
                        <li>- быстро заполняет любую, даже самую мелкую трещинку или щель</li>
                        <li>- обладает способностью растягиваться, сжиматься и принимать необходимую форму</li>
                        <li>- не боится сырости и влаги</li>
                        <li>- хорошо переносит экстремальные плюсовые и минусовые температуры</li>
                        <li>- не подвержен гниению, не интересен насекомым и птицам</li>
                        <li>- имеет долгий срок службы, который составляет более двадцати лет</li>
                  </ul>

                  <p>Узнать подробнее о герметизации можно на странице  <Link to='/germetizatsiya/germetizatsiya-sruba' className="nav-link dropdown-link">Герметизация срубов</Link></p>

                  <p>Компания «ГрутВуд» выполняет все виды работ по покраске и восстановлению срубов. 
                  Мы используем только проверенные материалы, прошедшие необходимую сертификацию. 
                  Все работы проводятся с обязательным соблюдением технологии и температурного режима. </p>
                        
             </Col>
           </Row>
         </Container>
           
        <FormQuickOrder className="mt-5" />
        <ImagesView />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default PokraskaDomaIzSruba;